import React,{useState} from 'react';
import emailjs from 'emailjs-com';
import './App.css';

import logo from './logo.png'

const App = () =>{


  const [loading,setLoading] = useState(false)
  function sendEmail(e) {
    e.preventDefault();
    setLoading(true)
    emailjs.sendForm('pengaduankantahsalatiga', 'bpnpengaduan', e.target, 'user_qBWe9OzLphcLhH4ELVwlw')
      .then((result) => {
          alert("Pengisian berhasil, silahkan menunggu jawaban dari kami.");
          setLoading(false)
          window.location.reload();
      }, (error) => {
          setLoading(false)
          console.log(error.text);
      });
      e.target.reset()
  }

  return(
    <div style={{padding:20}}>
      <div style={{textAlign:'center'}}>
      <img style={{width:100,height:100}} src={logo} alt="logo" />

      </div>
    <h2 style={{textAlign:'center'}}>Form Pengaduan Kantor Pertanahan Kota Salatiga</h2>
    <p style={{textAlign:'center'}}>Silahkan mengisi form pengaduan dibawah ini menggunakan bahasa yang <b>BAIK</b> dan <b>SOPAN</b></p>
    <div className="container">
      <form onSubmit={sendEmail} >
      <div className="row">
          <div className="col-25">
            <label>Judul Pengaduan</label>
          </div>
          <div className="col-75">
            <input type="text" id="judul" name="judul" placeholder="Judul Pengaduan" />
          </div>
        </div>
        <div className="row">
          <div className="col-25">
            <label htmlFor="fname">Nama Lengkap</label>
          </div>
          <div className="col-75">
            <input type="text" id="name" name="name" placeholder="Nama Lengkap" />
          </div>
        </div>
        <div className="row">
          <div className="col-25">
            <label htmlFor="fname">Alamat</label>
          </div>
          <div className="col-75">
            <input type="text" id="alamat" name="alamat" placeholder="Alamat" />
          </div>
        </div>
        <div className="row">
          <div className="col-25">
            <label htmlFor="lname">E-Mail</label>
          </div>
          <div className="col-75">
            <input type="text" id="email" name="email" placeholder="Email" />
          </div>
        </div>
        <div className="row">
          <div className="col-25">
            <label htmlFor="fname">No Handphone</label>
          </div>
          <div className="col-75">
            <input type="text" id="telp" name="telp" placeholder="No Handphone" />
          </div>
        </div>
        
        <div className="row">
          <div className="col-25">
            <label htmlFor="subject">Pesan Pengaduan</label>
          </div>
          <div className="col-75">
            <textarea id="messsage" name="messsage" placeholder="Pesan Pengaduan" style={{height: '200px'}} defaultValue={""} />
          </div>
        </div>
        <div className="row">
          <div className="col-25">
            <label htmlFor="fname">Tanggal</label>
          </div>
          <div className="col-75">
            <input type="date" id="tgl" name="tgl"/>
          </div>
        </div>
        <div style={{paddingTop:20}} className="row">
          <input value="Kirim" type="submit" defaultValue="Submit" />
          {
            loading === true &&
                      <div style={{width:100,height:100}} class="loader"></div>

          }
        </div>
        
      </form>
     
    </div>
  </div>
  )
}

export default App;
